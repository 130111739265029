import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import { PatientpageContext } from "../context/GlobalContext";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { OverlayContext } from "../OverlayManager/Overlay";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/material/CircularProgress";
import { Ls } from "../utility/utility";
import Button from "../Tools/Button";

const AddServicesModal = () => {
  const doctorContext = useContext(PatientpageContext);
  const [recentService, setRecentServices] = useState([]);
  const [selectService, setSelectService] = useState("");
  const [serviceMsg, setServiceMsg] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [serviceData, setServiceData] = useState("");
  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));
  const overlay = useContext(OverlayContext);
  const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

  const newRecentService = [...recentService];
  const findexIndexOfSelectSerive =
    Array.isArray(newRecentService) &&
    !!newRecentService?.length &&
    !!selectService
      ? recentService?.findIndex(
          (e) => e.display_name === selectService?.display_name
        )
      : -1;
  const removeElementFromRecentService =
    findexIndexOfSelectSerive !== -1
      ? newRecentService.splice(findexIndexOfSelectSerive, 1)[0]
      : null;
  const updatedRecentService = removeElementFromRecentService
    ? [removeElementFromRecentService, ...newRecentService]
    : newRecentService;

  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const recentServices = async () => {
      const { data, error } = await doctorContext.getv2Call(
        "/v2/doctor/list/recent/opd/services",
        "",
        setLoader,
        true
      );
      if (data) {
        setRecentServices(data?.data?.records);
      }
    };
    recentServices();
  }, []);

  const handleFetchService = async (item) => {
    const { data, error } = await doctorContext?.postPutV2Call(
      "/v2/doctor/fetch/opd/service/details",
      "",
      { service: item?.service },
      setFetchLoader,
      false,
      true
    );
    if (data?.data) {
      setServiceData(data?.data);
    }
  };

  const handleServicOpd = async (values, Total, error) => {
    if (error && !Object.keys(error).length) {
      const options = {
        service: values?.service,
        fee: Number(values?.fee) * 100 || 0,
        discount_amount: Number(values?.discount) * 100 || 0,
        gst_applicable: values?.isgst,
        gst_percentage:values?.isgst? values?.gst : 0,
        calc_amount: Number(Total) * 100 || 0,
      };
      const { data, error } = await doctorContext?.postPutV2Call(
        "/v2/doctor/manage/opd/service/",
        appointment?.appointmentItem?.appointment_id,
        options,
        setSubmitting,
        true
      );
  
      if (data?.data) {
        setServiceMsg(data?.data?.message);
      }
    }
  };


  return (
    <div
      className={`addServicesModal ${
        serviceMsg ? "addServicesModal__submit" : ""
      }`}
    >
      {serviceMsg ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div>{serviceMsg}</div>
          <button
            onClick={() => {
              overlay?.context?.getServices();
              overlay.hide();
            }}
            type="button"
            className="static_button addServicesModal__form__button_save"
          >
            Ok
          </button>
        </div>
      ) : (
        <>
          {loader ? (
            <Typography
              className="d-flex w-100 justify-content-around"
              component="div"
              variant="h3"
            >
              <Skeleton sx={{ width: "40%" }} />
              <Skeleton sx={{ width: "40%" }} />
            </Typography>
          ) : recentService &&
            Array.isArray(recentService) &&
            !!recentService?.length ? (
            <div className="addServicesModal__header">
              <Stack direction="row" spacing={1}>
                {updatedRecentService?.map((item) => (
                  <Chip
                    label={item?.display_name}
                    clickable
                    className={
                      selectService?.service === item?.service
                        ? "addServicesModal__chip_active"
                        : "addServicesModal__chip"
                    }
                    size="small"
                    onClick={() => {
                      setSelectService(item);
                      handleFetchService(item);
                    }}
                  />
                ))}
              </Stack>
            </div>
          ) : (
            <></>
          )}
          <div className="addServicesModal__form">
            {fetchLoader ? (
              <>
                <Skeleton sx={{ fontSize: "4rem" }} />
                <Skeleton sx={{ fontSize: "4rem" }} animation="wave" />
                <Skeleton sx={{ fontSize: "4rem" }} animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              <Formik
                enableReinitialize
                validateOnMount
                initialTouched={{ zip: true }}
                initialValues={{
                  service: serviceData?.service || "",
                  fee: serviceData?.fee || "",
                  discount: serviceData?.discount_amount || "",
                  isgst: serviceData?.gst_applicable || false,
                  gst: serviceData?.sgst_percentage || "",
                }}
                validationSchema={Yup.object().shape({
                  service: Yup.string().required("Required"),
                  fee: Yup.number("Only Numbers")
                    .test("", "upto 2 dec.", (val) => {
                      if (val) {
                        return patternTwoDigisAfterComma.test(val);
                      }
                      return true;
                    })
                    .required("Required"),
                  discount: Yup.number("Only Numbers")
                    .test("", "upto 2 dec.", (val) => {
                      if (val) {
                        return patternTwoDigisAfterComma.test(val);
                      }
                      return true;
                    })
                    .required("Required"),
                  gst: Yup.number("Only Numbers").when("isgst", {
                    is: true,
                    then: (schema) =>
                      schema.positive("Positive").required("Required"),
                  }),
                })}
                onSubmit={() => {
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => {
                  const GrossTotal =
                    Number(values?.fee) - Number(values?.discount) || 0;
                  const Total =
                    values?.isgst && values?.gst
                      ? GrossTotal + (GrossTotal * values?.gst) / 100
                      : GrossTotal;
                  return (
                    <Form>
                      <div className="w-100 d-flex flex-column">
                        <Field
                          className="addServicesModal__form_input addServicesModal__form_service"
                          type="text"
                          name="service"
                          placeholder="Service"
                          value={values.service}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          className="addServicesModal__form_error"
                          component="div"
                          name="service"
                        />
                      </div>
                      <div className="addServicesModal__form_amount">
                        <div className="addServicesModal__form_curreny">
                          INR
                        </div>
                        <div className="d-flex flex-column addServicesModal__form_fee">
                          <Field
                            type="number"
                            className="addServicesModal__form_input"
                            name="fee"
                            placeholder="Fee"
                            value={values.fee}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            className="addServicesModal__form_error"
                            component="div"
                            name="fee"
                          />
                        </div>
                        <div className="d-flex flex-column addServicesModal__form_discount">
                          <Field
                            type="number"
                            className="addServicesModal__form_input"
                            name="discount"
                            placeholder="Discount"
                            value={values.discount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            className="addServicesModal__form_error"
                            component="div"
                            name="discount"
                          />
                        </div>
                      </div>
                      <div className="addServicesModal__form_applicable">
                        <div className="addServicesModal__form_isgst">
                          <span>GST Applicable ?</span>
                          <Field
                            type="checkbox"
                            name="isgst"
                            className="addServicesModal__form_radio"
                          />
                        </div>
                        <div className="d-flex flex-column addServicesModal__form_gst">
                          <Field
                            type="number"
                            className={`addServicesModal__form_input ${
                              !values?.isgst
                                ? "addServicesModal__form_gst_disabled"
                                : ""
                            }`}
                            name="gst"
                            placeholder="GST %"
                            value={values.gst}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            className="addServicesModal__form_error"
                            component="div"
                            name="gst"
                          />
                        </div>
                      </div>
                      <div className="addServicesModal__form_total">
                        <span>Total Amount -</span>
                        <span>{Number(Total)?.toFixed(2)}</span>
                      </div>
                      <div className="addServicesModal__form__button">
                        <button
                          onClick={() => {
                            handleServicOpd(values, Total, errors);
                            handleSubmit();
                          }}
                          type="button"
                          className="static_button addServicesModal__form__button_save"
                        >
                          {isSubmitting ? (
                            <CircularProgress
                              sx={{ color: "#FFF" }}
                              size={18}
                            />
                          ) : (
                            <>Save</>
                          )}
                        </button>
                        <button
                          onClick={() => {
                            overlay.hide();
                          }}
                          className="static_button addServicesModal__form__button_cancel"
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AddServicesModal;
