import { useState, useEffect, useContext } from "react";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
} from "@material-ui/core";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory, useLocation } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ServiceCall } from "../utility/service-call";
import { Ls } from "../utility/utility";
import { constants } from "../utility/constants";
import CloseIcon from "@material-ui/icons/Close";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { PatientpageContext } from "../context/GlobalContext";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import PageContentLayout from "./PageContentLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
}));
const AwardNRew = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [apiError, setApiError] = useState("");
  const doctorContext = useContext(PatientpageContext);
  const [filledFields, setFilledFields] = useState([]);
  const [formChanged, setFormChanged] = useState(false);

  const setState = doctorContext.setState;

  const [formData, setFormData] = useState({
    awards_list: [
      {
        award_name: "",
        year_award_received: "",
        awarded_by: "",
      },
    ],
    publications_list: [
      {
        name_of_paper_topic: "",
        year_of_publication: "",
        published_in: "",
      },
    ],
  });
  const [openModal, setOpenModal] = useState(false);
  const [openMemModal, setOpenMemModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ServiceCall.getv2(
          "/v2/doctor/myprofile/awards/publications/",
          `${constants.hospitalId("hosp_id")}`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );

        const { awards, publications } = response.data;
        setFormData({
          awards_list: awards.map((award) => ({
            award_name: award.award_name || "",
            year_award_received: award.year_award_received || "",
            awarded_by: award.awarded_by || "",
          })),
          publications_list: publications.map((publication) => ({
            name_of_paper_topic: publication.name_of_paper_topic || "",
            year_of_publication: publication.year_of_publication || "",
            published_in: publication.published_in || "",
          })),
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      awards_list: [
        {
          ...prevFormData.awards_list[0],
          [name]: value,
        },
      ],
    }));
  };

  const handleChange2 = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      publications_list: [
        {
          ...prevFormData.publications_list[0],
          [name]: value,
        },
      ],
    }));
  };
  const handleEducationChange = (index, event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      awards_list: prevFormData.awards_list.map((awards, i) => {
        if (i === index) {
          return {
            ...awards,
            [name]: value,
          };
        }
        return awards;
      }),
    }));
  };
  
  const handleEducationChange2 = (index, event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      publications_list: prevFormData.publications_list.map((publications, i) => {
        if (i === index) {
          return {
            ...publications,
            [name]: value,
          };
        }
        return publications;
      }),
    }));
  };


  const handleRemoveExperince = (indexToRemove) => {
    setFormData((prevState) => ({
      ...prevState,
      awards_list: prevState.awards_list.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
    setFilledFields((prevFilledFields) => {
      const newFilledFields = [...prevFilledFields];
      newFilledFields.splice(indexToRemove, 1);
      return newFilledFields;
    });
  };

  const handleRemovePublication = (indexToRemove) =>{
    setFormData((prevState) => ({
      ...prevState,
      publications_list: prevState.publications_list.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
    setFilledFields((prevFilledFields) => {
      const newFilledFields = [...prevFilledFields];
      newFilledFields.splice(indexToRemove, 1);
      return newFilledFields;
    });
  }


  const handleAddService = () => {
    const newRegistrationLength = formData.publications_list.length + 1;

    setFormData((prevFormData) => ({
      ...prevFormData,
      publications_list: [
        ...prevFormData.publications_list,
        {
          name_of_paper_topic: "",
          year_of_publication: "", // Initialize degree field with an empty string
          published_in: "",
        },
      ],
    }));
    setFilledFields((prevFilledFields) => [
      ...prevFilledFields,
      newRegistrationLength,
    ]);

  };

  const handleAddServic = () =>{
    const newRegistrationLength = formData.awards_list.length + 1;

    setFormData((prevFormData) => ({
      ...prevFormData,
      awards_list: [
        ...prevFormData.awards_list,
        {
          award_name: "",
          year_award_received: "", // Initialize degree field with an empty string
          awarded_by: "",
        },
      ],
    }));
    setFilledFields((prevFilledFields) => [
      ...prevFilledFields,
      newRegistrationLength,
    ]);

  }
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const Handlesub = await ServiceCall.putv2(
        "/v2/doctor/myprofile/awards/publications/",
        `${constants.hospitalId("hosp_id")}`,
        formData,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (Handlesub) {
        setLoading(false);
        setState({
          msg: Handlesub?.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate API call
      setSaved(true); // Update state to indicate form has been saved
    } catch (err) {
      if (err.response) {
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    }
  };

  useEffect(() => {
    const isFormChanged =
      formData.awards_list.some((award) => Object.values(award).some(value => value)) ||
      formData.publications_list.some((publication) => Object.values(publication).some(value => value));
    setFormChanged(isFormChanged);
  }, [formData]);

  const handlle = () => {
    if (formChanged) {
      const confirmBack = window.confirm("Are you sure you want to go back? Your changes will not be saved.");
      if (confirmBack) {
        history.push({
          pathname: "/my-profile",
          search: history.location.search,
        });
      }
    } else {
      history.push({
        pathname: "/my-profile",
        search: history.location.search,
      });
    }
  };
  const handlemem = () => {
    setOpenMemModal(true);
  };

  const handleCloseMo = () => {
    setOpenMemModal(false);
  };
  const handlememo = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleEducationSubmit = (event) => {
    event.preventDefault(); // Prevent form submission
    handleCloseModal();
  };
  const handleExperienceSubmit = (event) => {
    event.preventDefault(); // Prevent form submission
    handleCloseMo();
  };



  return (
    <>
      <div className="loginConti bodyBg">
        <div className="homepage">
          <Container component="main" maxWidth="xs">
      
            <div className="prescriptionContainer__heading" style={{marginLeft: "0.5rem"}}>

              Add Awards & Publications to display on your profile, You can add
              multiple awards and publications. Once done, please click SAVE.
            </div>
            <form
              className={classes.root}
              onSubmit={handleSubmit}
              style={{ marginTop: "2rem" }}
            >
              {/* <InputLabel id="awards_list" style={{ marginLeft: "-275px" }}>
                Award Name
              </InputLabel> */}
              <TextField
                labelId="awards_list"
                id="awards_list"
                name="awards_list"
                value={formData.awards_list
                  .map((awards) => awards.award_name)
                  .join(", ")}
                onChange={handleChange}
                label="Award Name"
                variant="outlined"
                fullWidth
                onClick={handlememo}
                required
                InputProps={{
                  // Add InputProps prop
                  // Add endAdornment to place the icon at the end
                  endAdornment: (
                    <ArrowRightIcon
                      style={{ cursor: "pointer" }}
                      onClick={handlememo}
                    />
                  ),
                }}
                style={{caretColor: "none", background: "#fff"}}
              />
              {/* <InputLabel
                id="memberships_list"
                style={{
                  marginLeft: "-240px",
                  marginBottom: "-11px",
                  marginTop: "30px",
                }}
              >
                Topic/Name of paper
              </InputLabel> */}
              <TextField
                labelId="memberships_list"
                id="memberships_list"
                name="practice_areas_list"
                // value={formData?.publications_list[0]?.name_of_paper_topic}
                value={formData.publications_list
                  .map((publications) => publications.name_of_paper_topic)
                  .join(", ")}
                onChange={handleChange2}
                label="Topic/Name of paper"
                variant="outlined"
                fullWidth
                onClick={handlemem}
                required
                style={{ marginTop: "20px", background: "#fff" }}
                InputProps={{
                  // Add InputProps prop
                  // Add endAdornment to place the icon at the end
                  endAdornment: (
                    <ArrowRightIcon
                      style={{ cursor: "pointer" }}
                      onClick={handlemem}
                    />
                  ),
                }}
              />

              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                style={{ height: "40px", marginTop: "50px" }}
                // disabled={saved}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : saved ? (
                  "Saved"
                ) : (
                  "Save"
                )}
              </Button>
            </form>
          </Container>
        </div>
      </div>

      <Modal
        open={openModal} // Control modal visibility using state
        onClose={handleCloseModal} // Close modal on outside click
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "20px",
          }}
        >
          <CloseIcon
            onClick={handleCloseModal}
            style={{ float: "right", cursor: "pointer" }}
          />
          <p
            style={{ textAlign: "center", fontSize: "20px", fontWeight: "500" }}
          >
            Add New Award
          </p>
          <form>
            {formData.awards_list.map((awards, index) => (
              <div key={index} 
              style={{
                border: filledFields[index] ? "2px solid lightgray" : "none",
                margin: "10px",
                // marginLeft: "2px",
                marginBottom: "10px",
                borderRadius: filledFields[index] ?"10px": "none",
                // backgroundColor: filledFields[index] ? "lightgray" : "",
              }}
>
                <Grid container spacing={2} 
                  style={{ marginLeft: "26px", marginBottom: "-20px" }}
                  >
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      label="Year award received"
                      variant="outlined"
                      name="year_award_received"
                      value={awards.year_award_received}
                      onChange={(event) => {
                        handleEducationChange(index, event);
                      }}
                      margin="normal"
                      required
                      style={{ marginBottom: "21px", backgroundColor: "#fff" }}

                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      label="Awarded by"
                      variant="outlined"
                      name="awarded_by"
                      value={awards.awarded_by}
                      onChange={(event) => {
                        handleEducationChange(index, event);
                      }}
                      margin="normal"
                      required
                      style={{ marginBottom: "30px", backgroundColor: "#fff" }}

                    />
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  label="Enter Name of Award"
                  variant="outlined"
                  name="award_name"
                  value={awards.award_name}
                  onChange={(event) => {
                    handleEducationChange(index, event);
                  }}
                  margin="normal"
                  required
                  style={{                     
                  marginBottom: "10px",
                  backgroundColor: "#fff",
}}
                />
                <RemoveCircleOutlineIcon
                  style={{
                    marginTop: "-21px",
                    cursor: "pointer",
                    marginBottom: "44px",
                    float: "right",
                    marginRight: "-27px",
                  }}
                 onClick={() => handleRemoveExperince(index)}
                />
              </div>
            ))}
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleAddServic}
              style={{ height: "40px", width: "50px"}}
            >
              Add+
            </Button>

            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ height: "40px" }}
              onClick={handleEducationSubmit} // Use the education submit handler
            >
              Add Award
            </Button>
          </form>
        </Box>
      </Modal>

      <Modal
        open={openMemModal} // Control modal visibility using state
        onClose={handleCloseMo} // Close modal on outside click
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "20px",
          }}
        >
          <CloseIcon
            onClick={handleCloseMo}
            style={{ float: "right", cursor: "pointer" }}
          />
          <p
            style={{ textAlign: "center", fontSize: "20px", fontWeight: "500" }}
          >
            Add New Publication
          </p>
          <form>
            {formData.publications_list.map((publications, index) => (
              <div key={index} 
              style={{
                border: filledFields[index] ? "2px solid lightgray" : "none",
                margin: "10px",
                marginBottom: "10px",
                borderRadius: filledFields[index] ? "10px" : "none",
              }}

              >
                <Grid container spacing={2}
                style={{ marginLeft: "26px", marginBottom: "-20px" }}>
                
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      label="Year of Publication"
                      variant="outlined"
                      name="year_of_publication"
                      value={publications.year_of_publication}
                      onChange={(event) => {
                        handleEducationChange2(index, event);
                      }}
                      margin="normal"
                      required
                      style={{ marginBottom: "21px", backgroundColor: "#fff" }}

                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      label="Published In"
                      variant="outlined"
                      name="published_in"
                      value={publications.published_in}
                      onChange={(event) => {
                        handleEducationChange2(index, event);
                      }}
                      margin="normal"
                      required
                      style={{ marginBottom: "30px", backgroundColor: "#fff" }}

                    />
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  label="Enter Your College/University Name"
                  variant="outlined"
                  name="name_of_paper_topic"
                  value={publications.name_of_paper_topic}
                  onChange={(event) => {
                    handleEducationChange2(index, event);
                  }}
                  margin="normal"
                  required
                  style={{
                    marginBottom: "10px",
                    backgroundColor: "#fff",
                  }}
                />
                <RemoveCircleOutlineIcon
                  style={{
                    marginTop: "-21px",
                    cursor: "pointer",
                    marginBottom: "44px",
                    float: "right",
                    marginRight: "-27px",
                  }}
                  onClick={() => handleRemovePublication(index)}
                />
              </div>
            ))}
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleAddService}
              style={{ height: "40px", width: "50px" }}
            >
              Add+
            </Button>

            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ height: "40px" }}
              onClick={handleExperienceSubmit} // Use the experience submit handler
            >
              
              Add Publication
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};
export default AwardNRew;
