import React, { useEffect, useContext, useState } from "react";
import AppointmentTab from "./AppointmentTab";
import NoAppointmentGif from "../static/images/no-appointment.gif";
import { PatientpageContext } from "./../context/GlobalContext";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarIcon from "../static/images/Combined-Shape.svg";
import Gap from "../Tools/Gap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import { countriesList } from "../utility/utility";
import { constants } from "../utility/constants";
import Paper from "@mui/material/Paper";

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  FormHelperText,
  Input,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { ServiceCall } from "../utility/service-call";
import { Ls } from "../utility/utility";
import ProfileCard from "./ProfileCard";
import SelectDoctor from "./SelectDoctor";
import CalendarMonth1 from "../Tools/CalendarMonth1";
import dayjs from "dayjs";
import Switch from "../../src/Tools/Swtich";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Overlay from "./Overlay";

const NewAppointmentTab = (props) => {
  const doctorContext = useContext(PatientpageContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false); // State for managing loading state
  const [openModal, setOpenModal] = useState(false);
  const [opensModal, setOpensModal] = useState(false);
  const [reportedProblem, setReportedProblem] = useState("");
  const [reportedProblemError, setReportedProblemError] = useState('');

  sessionStorage.setItem("reportedproblem", reportedProblem);

  const [switchState, setSwitchState] = useState(false);

  const [patientModal, setPatientModal] = useState(false);

  const [newTemplateName, setNewTemplateName] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91"); // State to hold the selected country code
  const [formData, setFormData] = useState({
    phone: "",
  });
  const [showProfilesModal, setShowProfilesModal] = useState(false);
  const [showSelectDoctorModal, setShowSelectDoctorModal] = useState(false);
  const [profilename, setProfilename] = useState("");
  const [profileage, setProfileage] = useState("");
  const [profilegen, setProfilegen] = useState("");
  const [profileid, setProfileid] = useState("");
  const [phonedata, setPhonedata] = useState("");
  const [newfile, setNewfile] = useState("");
  const [showLoader, setShowLoader] = useState(false); // State to control loader visibility

  const [formsData, setFormsData] = useState({
    name: "",
    age: "",
    gender: "male",
    email: "",
    profile_type: "",
    profile_id: "",
    birth_date: "",
  });
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [casefileid, setCasefileid] = useState("");
  sessionStorage.setItem("casefileid", casefileid);

  const [selectedRow, setSelectedRow] = useState(null);

  const profiles = phonedata?.profiles;
  const setState = doctorContext.setState;
  const doc_id = doctorContext?.doctordetail?.doc_id;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#199A8E",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  // const handlepatient = (event) => {
  //   const { name, value } = event.target;
  //   setFormsData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  const fetchCases = async (profileId) => {
    setShowLoader(true);
    try {
      const fetchcash = await ServiceCall.getv2(
        `/v2/doctor/list/patient/cases/`,
        `${constants.hospitalId("hosp_id")}/${profileId}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (fetchcash && fetchcash.data && fetchcash.data.cases) {
        setShowLoader(false);
        setNewfile(fetchcash.data.cases);
      }
    } catch (err) {}
  };

  const handleAgeChange = (event) => {
    const age = event.target.value;
    const currentDate = dayjs();
    const calculatedBirthDate = currentDate
      .subtract(age, "year")
      .format("YYYY-MM-DD");

    setFormsData((prevData) => ({
      ...prevData,
      age: age,
      birth_date: calculatedBirthDate, // Set calculated birth_date
    }));
  };

  const handleDOBChange = (value) => {
    const formattedDate = dayjs(value).format("YYYY-MM-DD");
    const age = dayjs().diff(dayjs(formattedDate), "year");
    setFormsData((prevData) => ({
      ...prevData,
      birth_date: formattedDate,
      age: age.toString(), // Set calculated age as string
    }));
  };

  const handlecontacts = () => {
    setPatientModal(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const body = {
        name: formsData?.name,
        gender: formsData?.gender,
        email: formsData?.email,
        age: formsData?.age,
        profile_type: formsData?.profile_type,
        birth_date: formsData?.birth_date,
        address: "",
        city: "",
        country: "",
        pincode: "",
        state: "",
      };

      let response;

      if (formsData.profile_id) {
        // If profile_id exists, it's an update operation (PUT)
        response = await ServiceCall.putv2(
          "/v2/doctor/fetch/patient/profile/",
          `${constants.hospitalId("hosp_id")}/${formsData.profile_id}`,
          body,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
      } else {
        // If no profile_id, it's a create operation (POST)
        response = await ServiceCall.postv2(
          "/v2/doctor/create/patient/profile/",
          `${constants.hospitalId("hosp_id")}/${"+91"}${formData?.phone}`,
          body,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
      }

      if (response) {
        handlecontact();
        setLoading(false);
        setShowProfilesModal(true);
        setState({
          msg: response.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
        setPatientModal(false); // Close the edit/create modal
      }
    } catch (err) {
      console.error("Error submitting profile:", err);
      setLoading(false);
      // Handle error (e.g., show an error message to the user)
    }
  };
  const handleCloseModal = () => {
    setOpensModal(false);
    setSelectedProfileId(null);
    setSwitchState(false);
    setReportedProblem("");
  };
  const handleChange2 = (event) => {
    setReportedProblem(event.target.value);
    if (event.target.value.trim() === '') {
      setReportedProblemError('Please fill in the reported problems');
    } else {
      setReportedProblemError('');
    }
  };

  const handleSwitchChange = async (event) => {
    const newSwitchState = event.target.checked;
    setSwitchState(newSwitchState);
  
    if (!newSwitchState && selectedProfileId) {
      await fetchCases(selectedProfileId);
    }
  };
  
  const handlecl = () => {
    setShowProfilesModal(false);
    setOpenModal(true);
  };
  const handleChange = (value, label) => {
    let start =
      label == "StartDate" ? value : doctorContext?.appointmentDate?.StartDate;
    let end =
      label == "EndDate" ? value : doctorContext?.appointmentDate?.EndDate;

    doctorContext.getAppointment(start, end);
    doctorContext?.setAppointmentDate({
      ...doctorContext?.appointmentDate,
      [label]: value,
    });
  };
  const handlle = () => {
    history.push({
      pathname: "/homie",
      search: history.location.search,
    });
  };
  const CalendarMonth = ({ label, formate, handleChange, date }) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="newappointment__container__calender__datePicker">
          <MobileDatePicker
            label={label}
            inputFormat={formate}
            value={date}
            onChange={(value) => handleChange(value, label)}
            renderInput={(params) => <TextField {...params} />}
          />
          <img src={CalendarIcon} />
        </div>
      </LocalizationProvider>
    );
  };

  const handlerefresh = () => {
    doctorContext.getAppointment();
  };

  const hadledoctordata1 = () => {
    setOpenModal(true);
  };

  const handlecontact = async () => {
    setLoading(true);
    try {
      const phonefetch = await ServiceCall.getv2(
        "/v2/doctor/fetch/patient/profiles/",
        `${constants.hospitalId("hosp_id")}/${"+91"}${formData?.phone}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (phonefetch) {
        // Store all profile IDs or the whole profiles array
        // setProfileid(phonefetch.data.profiles.map(profile => profile.profile_id));
        // or
        // setProfiles(phonefetch.data.profiles);

        setLoading(false);
        setState({
          msg: phonefetch.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
        setPhonedata(phonefetch.data);
        setShowProfilesModal(true);
        setOpenModal(false);
      }
    } catch (err) {
      setLoading(false);
      setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };
  const isValidPhoneNumber = (phoneNumber) => {
    // Remove any non-numeric characters from the phone number
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

    // Check if the phone number has exactly 10 digits and contains only numeric characters
    return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
  };

  const handleChange1 = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
    if (name === "phone") {
      if (!isValidPhoneNumber(value)) {
        // Define isValidPhoneNumber according to your validation logic
        setPhoneError(true); // Set phone number error state
      } else {
        setPhoneError(false); // Reset phone number error state
      }
    }
  };

  const handleCloseMo = () => {
    setOpenModal(false);
  };

  // const handleChange2 = (event) => {
  //   setReportedProblem(event.target.value);
  // };

  // const handleSwitchChange = () => {
  //   setSwitchState(prevState => !prevState);
  // };

  const handleSelectProfile = async (profileId, profilename, proage, progender) => {
    setOpensModal(true);
    setSelectedProfileId(profileId);
    setProfileid(profileId);
    setProfilename(profilename);
    setProfileage(proage);
    setProfilegen(progender);
    await fetchCases(profileId);
  };

  const handlemandal = () => {
    if (switchState) {
      // If creating a new case
      if (!reportedProblem.trim()) {
        setReportedProblemError('Please fill in the reported problems');
        return; // Prevent further execution
      }
      // Clear any existing error
      setReportedProblemError('');
      // Here you can add logic to handle the new case with reportedProblem
    } else {
      // If selecting an existing case
      if (selectedRow) {
        setCasefileid(selectedRow?.case_file_id);
        // Here you can add your logic to handle the selected case ID
      } else {
        console.log("No row selected");
        return; // Prevent further execution
      }
    }
    setShowSelectDoctorModal(true);
  };

  const handleEditClick = async (profileId, event) => {
    event.stopPropagation();
    try {
      const Editpatient = await ServiceCall.getv2(
        "/v2/doctor/fetch/patient/profile/",
        `${constants.hospitalId("hosp_id")}/${profileId}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (Editpatient) {
        setFormsData({
          ...Editpatient.data,
          profile_id: profileId, // Add this line
          phoneNumber: Editpatient.data.phone, // Assuming the API uses 'phone' but your form uses 'phoneNumber'
        });
        setPatientModal(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handlepatient = (event) => {
    const { name, value } = event.target;
    setFormsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "age") {
      const currentDate = new Date();
      const birthYear = currentDate.getFullYear() - parseInt(value);
      const estimatedBirthDate = new Date(
        birthYear,
        currentDate.getMonth(),
        currentDate.getDate()
      );
      setFormsData((prevData) => ({
        ...prevData,
        birth_date: estimatedBirthDate.toISOString().split("T")[0], // Format as YYYY-MM-DD
      }));
    }
  };

  return (
    <>

{showLoader && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <div className="newappointment__container">
        <div className="newappointment__container__calender">
          {/* <Navigation text="Appointment" handleClick={()=>props.navigate(0)}/> */}
          <div style={{ marginLeft: "2rem" }}>
            <div
              style={{
                float: "inline-end",
                marginRight: "1rem",
                cursor: "pointer",
              }}
              onClick={handlerefresh}
            >
              <AutorenewIcon style={{ width: "32px", height: "32px" }} />
            </div>
            <div className="newappointment__container__calender__text">
              Select start date and end date from calendar to list appointments
            </div>

            <div className="newappointment__container__calender__date">
              <CalendarMonth
                label="StartDate"
                formate="YYYY-MM-DD"
                date={doctorContext?.appointmentDate?.StartDate}
                handleChange={handleChange}
              />
              <CalendarMonth
                label="EndDate"
                formate="YYYY-MM-DD"
                date={doctorContext?.appointmentDate?.EndDate}
                handleChange={handleChange}
              />
              <div
                className="appointment__date__record"
                style={{
                  display: "flex",
                  alignItems: "center",
                  // flex: 1,
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={hadledoctordata1}
                // disabled={loading}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Book"
                  )}{" "}
                  <AddIcon sx={{ fontSize: "25px", marginLeft: "0.5rem" }} />
                </span>
              </div>
            </div>
            <p className="newappointment__container__calender__text">
              Click on the appointment card to view or action
            </p>
          </div>
        </div>
        <AppointmentTab
          toggleAppointment={props.toggleAppointment}
          showAppointment={props.showAppointment}
          appointmentList={props.appointmentList}
          classes={props.classes}
          appointMentLoader={props.appointMentLoader}
          navigation="/my-appointments"
          tabRoute="appointment"
          noDataText={
            <div>
              <img
                style={{ width: "60%", float: "inline-start" }}
                src={NoAppointmentGif}
                alt="no appointment found"
              />
              <p></p>
            </div>
          }
        />
        <Gap size="3rem" />
      </div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
            height: "75vh",
          }}
        >
          <ArrowBackIosIcon
            onClick={handleCloseMo}
            style={{ float: "left", cursor: "pointer" }}
          />
          <span
            style={{
              marginBottom: "1rem",
              fontSize: "17px",
              fontWeight: "500",
              display: "flex",
            }}
          >
            Book Appointment
          </span>
          <Box
            sx={{
              backgroundColor: "rgb(25 154 142)",
              padding: "4px",
              marginBottom: "14rem",
            }}
          >
            <Typography
              variant="h5"
              align="center"
              color="white"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Enter patient mobile no. to fetch profiles or create new profile
            </Typography>
          </Box>

          <FormControl
            variant="filled"
            style={{ width: "100%", marginBottom: "2rem" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "-6rem",
              }}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={phoneCode}
                onClick={(event) => event.stopPropagation()}
                className="w-23"
                // classes={{ select: classes.select }}
              >
                {countriesList &&
                  countriesList.map((item, index) => (
                    <MenuItem value={item.code} key={item.code + "-" + index}>
                      {item.code}
                    </MenuItem>
                  ))}
              </Select>

              <InputLabel
                htmlFor="phoneNumber"
                style={{ marginLeft: "71px", marginTop: "-6rem" }}
              >
                Phone Number*
              </InputLabel>
              <Input
                id="phoneNumber"
                name="phone"
                value={formData?.phone}
                onChange={handleChange1}
                label="Phone Number"
                required
                error={phoneError}
                style={{ flex: 1, marginLeft: 6, width: "100px" }} // Adjust the margin and flex properties
              />
            </div>
            {phoneError && (
              <FormHelperText error>
                Please enter a valid phone number.
              </FormHelperText>
            )}
          </FormControl>
          <Button
            variant="contained"
            // color="#199a8e"
            fullWidth
            style={{ background: "#199a8e", color: "#fff" }}
            onClick={handlecontact}
            disabled={loading} // Disable button when loading is true
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Fetch Details"
            )}
          </Button>
        </Box>
      </Modal>
      <Modal
        open={showProfilesModal}
        onClose={() => setShowProfilesModal(false)}
        aria-labelledby="profiles-modal-title"
        aria-describedby="profiles-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <ArrowBackIosIcon
            onClick={handlecl}
            style={{ float: "left", cursor: "pointer" }}
          />
          <Box
            sx={{
              backgroundColor: "rgb(25 154 142)",
              padding: "4px",
              marginBottom: "2rem",
            }}
          >
            <Typography
              variant="h5"
              align="center"
              color="white"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              {" "}
              Select from available profiles for booking
            </Typography>
          </Box>
          {profiles &&
            profiles.map((profile) => (
              <ProfileCard
                key={profile.profile_id}
                name={profile.name}
                gender={profile.gender}
                age={profile.age}
                email={profile.email}
                profile_id={profile.profile_id}
                profileType={profile.profile_type}
                onClick={() =>
                  handleSelectProfile(
                    profile.profile_id,
                    profile.name,
                    profile.gender,
                    profile.age
                  )
                }
                oneditClick={handleEditClick}
              />
            ))}
          <Button
            variant="contained"
            // color="#199a8e"
            fullWidth
            style={{ background: "#199a8e", color: "#fff" }}
            onClick={handlecontacts}
            disabled={loading} // Disable button when loading is true
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Add New"
            )}
          </Button>
        </Box>
      </Modal>

      <Modal
        open={patientModal}
        onClose={() => setPatientModal(false)}
        aria-labelledby="select-doctor-modal-title"
        aria-describedby="select-doctor-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <ArrowBackIosIcon
            onClick={() => setPatientModal(false)}
            style={{ float: "left", cursor: "pointer", marginTop: ".5rem" }}
          />
          <Typography
            variant="h4"
            gutterBottom
            style={{ fontSize: "21px", fontWeight: "500", marginTop: ".5rem" }}
          >
            Patient Information
          </Typography>
          <FormControl variant="filled" fullWidth required>
            <InputLabel id="profile-type">Profile Type</InputLabel>
            <Select
              labelId="profile-type"
              id="profile-type"
              name="profile_type"
              label="Profile Type"
              value={formsData?.profile_type}
              onChange={handlepatient}
            >
              <MenuItem value="family">Family</MenuItem>
              <MenuItem value="self">Self</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            variant="filled"
            label="Patient Name"
            name="name"
            value={formsData?.name}
            onChange={handlepatient}
            margin="normal"
            required
          />
          <Box display="flex" alignItems="center" gap={2}>
            <TextField
              variant="filled"
              label="Age"
              name="age"
              type="number"
              value={formsData?.age}
              onChange={handleAgeChange}
              required
              style={{ width: "52%" }}
              // disabled={!!formsData.birth_date} // Disable if birth_date is set
            />
            <Typography>Or</Typography>
            <CalendarMonth1
              label="DOB"
              format="YYYY-MM-DD"
              date={formsData.birth_date}
              handleChange={handleDOBChange}
              InputProps={{
                inputProps: {
                  max: dayjs().format("YYYY-MM-DD"), // Set max date to today
                  onKeyPress: (e) => {
                    e.preventDefault();
                  },
                },
              }}
              style={{ flexGrow: 1 }}
              // disabled={!!formsData.age} // Disable if age is set
            />
          </Box>

          <RadioGroup
            name="gender"
            value={formsData?.gender}
            onChange={handlepatient}
            row
            sx={{ marginY: 2 }}
          >
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="Female"
            />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>

          <TextField
            fullWidth
            variant="filled"
            label="Email ID"
            name="email"
            type="email"
            value={formsData?.email}
            onChange={handlepatient}
            margin="normal"
            required
          />

          <Button
            variant="contained"
            // color="#199a8e"
            type="submit"
            onClick={handleSubmit}
            fullWidth
            style={{ background: "#199a8e", color: "#fff" }}
            disabled={loading} // Disable button when loading is true
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </Modal>

      {/* SelectDoctor Modal */}
      <Modal
        open={showSelectDoctorModal}
        onClose={() => setShowSelectDoctorModal(false)}
        aria-labelledby="select-doctor-modal-title"
        aria-describedby="select-doctor-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <ArrowBackIosIcon
            onClick={() => setShowSelectDoctorModal(false)}
            style={{ float: "left", cursor: "pointer", marginTop: "-1rem" }}
          />

          <SelectDoctor
            profileid={profileid}
            profill={profilename}
            setopn={setOpenModal}
            proage={profileage}
            progen={profilegen}
          />
        </Box>
      </Modal>
      <Modal
        open={opensModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "20px",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create a new case of select existing
          </Typography>

          <FormControlLabel
            label="Create A New Case ID"
            control={
              <Switch checked={switchState} handleChange={handleSwitchChange} />
            }
            style={{marginLeft: "0.5rem"}}
          />

          {switchState && (
            <>
              <TextField
      fullWidth
      multiline
      rows={5}
      label="Reported problems"
      variant="outlined"
      value={reportedProblem}
      onChange={handleChange2}
      margin="normal"
      required
      error={!!reportedProblemError}
      helperText={reportedProblemError}
      style={{ marginBottom: "21px", backgroundColor: "#fff" }}
    />
            </>
          )}

          {!switchState && (
            <div
              style={{ maxHeight: "300px", overflowY: "auto" }}
              className="mt-4"
            >
              <TableContainer
                component={Paper}
                style={{ marginBottom: ".5rem" }}
              >
                <Table sx={{ minWidth: 300 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Date</StyledTableCell>
                      <StyledTableCell align="center">Case ID</StyledTableCell>
                      <StyledTableCell align="center">
                        Reported Problem
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {newfile && newfile.length > 0 ? (
                      newfile.map((row, index) => (
                        <StyledTableRow 
                        
                        key={index}
                        onClick={() => setSelectedRow(row)}
                        style={{
                          backgroundColor:
                            selectedRow === row ? "#e3f2fd" : "inherit",
                          cursor: "pointer",
                        }}
                        
                        >
                          <StyledTableCell align="center">
                            {row?.date || ""}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.case_id || ""}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.reported_problem || ""}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={3} align="center">
                          No data available
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "1rem"
            }}
          >
            <Button
              variant="secondary"
              style={{ backgroundColor: "#6c757d", borderColor: "#6c757d", color: "#fff" }}
              onClick={() =>     setOpensModal(false)
              }
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={handlemandal}
              disabled={!selectedRow && !switchState}
              style={{ backgroundColor: "#199a8e", color: "#fff" }}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default NewAppointmentTab;
