import axios from "axios";
import { blogUrl, baseUrl, constants } from "./constants";

const urlPath = `${baseUrl}`;
const urlPathMode = `${"/" + process.env.REACT_APP_MODE}`;
const urlMode = process.env.REACT_APP_MODE;
const mode = `${process.env.REACT_APP_MODE + "/"}`;
export class ServiceCall {
  static blog(url, headers) {
    return axios.get(`${blogUrl}${url}`, { headers });
  }
  static getv2(url, id, headers) {
    return axios.get(`${urlPath}${url}${mode}${id}`, { headers });
  }

  // static getv2new(url, headers) {
  //   return axios.get(`${urlPath}${url}${urlMode}`, { headers });
  // }

  static getnewv2(url, id, headers) {
    return axios.get(`${urlPath}${url}${urlPathMode}${id}`, { headers });
  }
  static getnewv3(url, headers) {
    return axios.get(`${urlPath}${url}`, { headers });
  }
  
  static gett(url, headers) {
    return axios.get(`${urlPath}${url}${urlPathMode}`, { headers });
  }
  static newget(url) {
    return axios.get(`${urlPath}${url}`);
  }
  static newget(url){
    return axios.get(`${urlPath}${url}`);

  }

  static postnewv2(url, id, options, headers) {
    return axios.post(`${urlPath}${url}${urlPathMode}${id}`, options, {
      headers,
    });
  }
  static postv2(url, id, options, headers) {
    return axios.post(`${urlPath}${url}${mode}${id}`, options, { headers });
  }
  static posttttv2(url, id, headers) {
    return axios.post(`${urlPath}${url}${mode}${id}`, { headers });
  }

  static posttv2(url,options, headers){
    return axios.post(`${urlPath}${url}${mode}`,options, { headers });

  }
  static posttttv2(url,options, headers){
    return axios.post(`${urlPath}${url}${urlMode}`,options, { headers });

  }

  static putv2(url, id, options, headers) {
    return axios.put(`${urlPath}${url}${mode}${id}`, options, { headers });
  }

  static deletev2(url, id, headers) {
    return axios.delete(`${urlPath}${url}${mode}${id}`, { headers });
  }
  static get(hospitalurl, mode, headers) {
    return axios.get(`${urlPath}${mode}${hospitalurl}`, { headers });
  }

  static post(hospitalurl, mode, options, headers) {  
    return axios.post(`${urlPath}${hospitalurl}${mode}`, options, { headers });
  }
  static put(url,options){
    return axios.put(`${urlPath}${url}${urlMode}`, options);
  }

  static postt(url,options){
    return axios.post(`${urlPath}${url}${urlMode}`,options);

  }
}

export const apiCall = (options, filterEnv) => {
  const modifiedUrl = {
    ...options,
    url: `${filterEnv ? baseUrl : urlPath}${options.url}${mode}${
      options.hosID
    }${options.extraField||""}`,
  };
  return axios(modifiedUrl);
};

export const apiCallOld = (options, filterEnv) => {
  const modifiedUrl = {
    ...options,
    url: `${filterEnv ? baseUrl : urlPath}${options.url}`,
  };
  return axios(modifiedUrl);
};
