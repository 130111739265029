import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { OverlayContext } from "../OverlayManager/Overlay";

import { Grid, Button } from "@mui/material";
import Rating from "@mui/material/Rating";
import { ServiceCall } from "./../utility/service-call";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import NotesIcon from "@mui/icons-material/Notes";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
// import { convertTime24to12, Ls } from '../utility/utility';
import ImageAvatar from "./ImageAvatar";
import DownloadIcon from "../static/images/download_icon.svg";
import RxoneImage from "../static/images/Rx_symbol-removebg-preview 1.svg";
import TimeIcon from "../static/images/timericon.svg";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { convertTime24to12, Ls, ErrorComponent } from "../utility/utility";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import { constants } from "../utility/constants";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { PatientpageContext } from "../context/GlobalContext";
import PatientReportComponent from "./PatientReportComponent";
import { CircularProgress } from "@mui/material";
import { OverlayType } from "../OverlayManager/Context";
import dayjs from "dayjs";
import { AppointmentIcons } from "../static/images/ImageManager";
import JoinComponent from "./JoinComponent";
import DialogComponent from "./DialogComponent";
import { apiCall } from "./../utility/service-call";
import { Img } from "react-image";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TooltipComponent from "./TooltipComponent";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";

const modalHeading = {
  editPrescription: "Edit Prescription",
  inperson: "In-Person Appointment",
  appointmentCall: "Online (Tele) Appointment",
};

export default function DoctorsAppointmentListItem({
  classes,
  tabRoute = "",
  index,
  handleOnClick,
  appointmts
}) {
  const doctorContext = useContext(PatientpageContext);
  const appointmentItem = appointmts;

  const [selectedDetailIndex, setSelectedDetailIndex] = useState("");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [openJoinModal, setOpenJoinModal] = useState(false);
  const [allowFollowup, setAllowFollowup] = useState(
    appointmentItem?.followup_allowed
  );
  const [dialogChild, setDialogChild] = useState(null);
  const [openReport, setOpenReport] = useState(false);
  const [disableShare, setdisableShare] = useState(true);
  const Overlay = useContext(OverlayContext);
  const [dialogType, setDialogType] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    dayjs(appointmentItem?.appointment_date).add(3, "days").format("YYYY-MM-DD")
  );
  const [disableCalendar, setDisableCalendar] = useState(true);
  const history = useHistory();
  // const appointmentIds = appointmentItem.map(appointment => appointment.appointment_id);

  
  
  const handleDonwnloadPrescription = async () => {
    let url = "/v2/doctor/prescriptions/download/";
    try {
      const PrescriptionDownload = await apiCall({
        url,
        method: "get",
        responseType: "blob",
        hosID: appointmentItem?.appointment_id,
        headers: {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        },
      });

      if (PrescriptionDownload) {
        let blob = new Blob([PrescriptionDownload.data], {
          type: "application/pdf",
        });
        let url = window.URL.createObjectURL(blob);
        Overlay.show(OverlayType?.ModalWindow, {
          data: (
            <div className="prescription__pdf">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
                <Viewer fileUrl={url} />
              </Worker>
              <div className="prescription__pdf__container">
                <a
                  href={url}
                  target="_blank"
                  className="prescription__pdf__button"
                >
                  <LocalPrintshopIcon />
                </a>
                <a
                  download={`prescription-${appointmentItem?.appointment_id}`}
                  href={url}
                  className="prescription__pdf__button"
                >
                  <DownloadForOfflineIcon />
                </a>
              </div>
            </div>
          ),
          handleClose: () => {},
        });
        // a.download = appointmentItem?.appointment_id;
        // document.body.append(a);
        // a.click();
        // a.remove();
        // window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.log("error",err)
      err &&
        doctorContext.setState({
          msg:
            err?.response?.data?.message ||
            "Network Error please try after some time",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
    }
  };


  const joinLogic = () => {
    if (doctorContext.checkDisable(appointmentItem)) {
      doctorContext.dialogController({
        open: true,
        content: (
          <div className="text-center">
            <p className="font-weight-bold mb-2">
              You can join the meeting at scheduled time only.
            </p>
          </div>
        ),
        maxWidth: "xs",
        disableBackdropClick: true,
        promptWindow: true,
      });
    } else {
      if (appointmentItem?.appointment_type !== constants.online) {
        openJoin("inperson");
      } else {
        openJoin("online");
      }
    }

    // openJoin()
  };

  const showPatientDetails = (index) => {
    if (selectedDetailIndex == index) {
      console.log("this is clicked");
      setSelectedDetailIndex("");
    } else {
      setSelectedDetailIndex(index);
    }
  };

  function rmApp(id) {
    let url = `/doctor/appointment/cancel/request/${id}`;
    ServiceCall.post(url, "", {
      doc_token: Ls.get("userToken"),
    })
      .then((res) => {
        doctorContext.dialogController({
          open: true,
          content: res.data.message,
          maxWidth: "sm",
        });
        doctorContext.getAppointment();
      })
      .catch((err) => {
        setDeleteLoader(false);
        if (err.response) {
          if (err.response.status === 401) {
            doctorContext.dialogController({
              open: true,
              content: err.response.data.message,
              maxWidth: "sm",
            });
          }
          setApiError("Failed with response " + err.response.data);
        } else if (err.request) {
          setApiError("Failed request " + err);
        } else {
          setApiError("Failed in general " + err);
        }
      });
  }

  const AppointmentIcon = ({ type }) => {
    if (type?.includes("Online")) {
      return <Img src={AppointmentIcons.online} />;
    } else if (type?.includes("In-Person")) {
      return <Img src={AppointmentIcons.inperson} />;
    }
    return <Img src={AppointmentIcons.walkin} />;
  };

  const removeAppointment = () => {
    doctorContext.handleDialogClose();
    setDeleteLoader(true);
    rmApp(appointmentItem?.appointment_id);
  };
  const followUpSwitch = (e) => {
    setAllowFollowup(e.target.checked);
    if (e.target.checked) {
      updateFollowup(
        dayjs(appointmentItem?.appointment_date)
          .add(3, "days")
          .format("YYYY-MM-DD")
      );
    }
  };
  const updateFollowup = (value) => {
    // PUT {{url}}/test/doctor/appointment/followup/status/<string:appointment_id>
    setDeleteLoader(true);
    let url = `/doctor/appointment/followup/status/${appointmentItem?.appointment_id}`;
    ServiceCall.put(
      url,
      { followup_by: value },
      {
        doc_token: Ls.get("userToken"),
      }
    )
      .then((res) => {
        setDeleteLoader(false);
        // doctorContext.dialogController({
        //   open: true,
        //   content: res.data.message,
        //   maxWidth: 'sm'
        // })
      })
      .catch((err) => {
        setDeleteLoader(false);
      });
  };
  const removeAppointmentConfirm = () => {
    doctorContext.dialogController({
      open: true,
      content: (
        <div className="text-center">
          <p className="font-weight-bold mb-2">
            Do you want to cancel the appointment?
          </p>
          {/* <span className="subscript">
              If you cancel the appointment now, we might need to deduct the
              processing fee from the refund.
            </span> */}
        </div>
      ),
      dialogAction: (
        <Grid container>
          <Grid
            item
            className="justify-content-center w-100 text-center d-flex"
          >
            <Button
              variant="contained"
              color="error"
              className="mr-2"
              size="small"
              onClick={removeAppointment}
            >
              Proceed
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={doctorContext.handleDialogClose}
              size="small"
            >
              Nevermind
            </Button>
          </Grid>
        </Grid>
      ),
      maxWidth: "xs",
      disableBackdropClick: true,
      promptWindow: true,
    });
    // doctorContext.deleteRecord(recordItem.name);
  };
  const openJoin = (joinType) => {
    // doctorContext.dialogController({
    //   open: true,
    //   content: <JoinComponent classes={classes} dialogType={joinType} appointment={appointmentItem} />,
    //   maxWidth: "xl",
    //   dialogAction: null
    // });
    setDialogType(joinType);
    setDialogChild(
      <JoinComponent
        classes={classes}
        dialogType={joinType}
        appointment={appointmentItem}
      />
    );
  };

  const StatusButton = ({ type, color }) => {
    if (type === "Confirmed") {
      return (
        <button className="loginContainer_button appointment__container__status__button">
          {type}
        </button>
      );
    } else {
      return (
        <button className="loginContainer_button appointment__container__status__button button_token">
          {type}
        </button>
      );
    }
    return <></>;
  };

  const closeDialog = () => {
    setDialogChild(null);
  };
  const closeReportDialog = () => {
    setOpenReport(false);
  };
  function checkShareDisable() {
    setdisableShare(false);
  }


  const handleRowclick = (appointmentId) => {
    const params = new URLSearchParams(history.location.search);
    params.set("app_id", appointmentId);
    history.push({
      pathname: "/my-appointments",
      search: params.toString(),
    });
  };
  return (
    <div
    key={index + 1}
    className={`py-2 px-3 appointment__container ${
      tabRoute === "prescription" ? "prescription__list__container" : ""
    }
    `}
    container
    spacing={2}
    item
    xs={11}
    justifyContent="space-around`"
    alignItems="center"
    style={{ cursor: "pointer", background:"#e8f3f1"}}
  >
      {/*report dialog start*/}
      {openReport && (
        <DialogComponent
          closeDialog={closeReportDialog}
          openProps={openReport}
          title={"Patient Record"}
          render={() => (
            <PatientReportComponent
              appointmentId={appointmentItem?.appointment_id}
            />
          )}
        ></DialogComponent>
      )}
      {dialogChild && (
        <DialogComponent
          closeDialog={closeDialog}
          openProps={dialogChild}
          maxWidth="xl"
          hideTitle={true}
          titleComponent={false}
          contentClass="px-3"
          contentStyle={{ display: "flex", alignItems: "stretch" }}
          render={() => dialogChild}
        ></DialogComponent>
      )}

      {deleteLoader && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <Table>
        <TableHead>
          <TableRow>
            {tabRoute === "prescription" ? <TableCell >Action</TableCell> : null}
            <TableCell style={{textAlign: "center"}}>Patient Name</TableCell>
            <TableCell style={{textAlign: "center"}}>Gender</TableCell>
            <TableCell style={{textAlign: "center"}}>Age</TableCell>
            <TableCell style={{textAlign: "center"}}>Type</TableCell>
            <TableCell style={{textAlign: "center"}}>Prescription Status</TableCell>
            {tabRoute === "prescription" ? (
              <TableCell style={{textAlign: "center"}}>Ratings</TableCell>
            ) : (
              <TableCell style={{textAlign: "center"}}>Time</TableCell>
            )}

            {tabRoute === "prescription" ? (
              <TableCell style={{textAlign: "center"}}>Feedback</TableCell>
            ) : (
              <TableCell style={{textAlign: "center"}}>Appointment Status</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
      {appointmentItem.map((appointmentItemss, index) => (
        <TableRow
          key={index}
          onClick={() => handleOnClick(appointmentItemss, index)}
          hover
        >

              {tabRoute === "prescription" && (
                <TableCell style={{textAlign: "center"}}>
                  <Grid sm={4} xm={4}>
                    <RemoveRedEyeIcon
                      sx={{ width: "100%", color: "#199a8e", fontSize: "3rem" }}
                      onClick={handleDonwnloadPrescription}
                      style={{textAlign: "center"}}
                    />
                  </Grid>
                </TableCell>
              )}
              <TableCell
                onClick={() => {
                  showPatientDetails(index + 1);
                }}
                className="appointment__container__middleSection"
                style={{textAlign: "center"}}
              >
                {appointmentItemss?.patient_name || "Unknown"}
              </TableCell>
              <TableCell style={{textAlign: "center"}}>
                {appointmentItemss?.patient_gender  || "NA"}
              </TableCell>
              <TableCell style={{textAlign: "center"}}>{appointmentItemss?.patient_age || "NA"}</TableCell>
              <TableCell style={{textAlign: "center"}}>
                <AppointmentIcon
                  type={
                    appointmentItemss?.app_type ||
                    appointmentItemss?.appointment_type
                  }
                />
                {" "}
                {(
                  appointmentItemss?.app_type || appointmentItemss?.appointment_type
                )
                  ?.replace("Scheduled ", "")
                  ?.replace("(", "")
                  ?.replace(")", "")}
              </TableCell>
              <TableCell style={{textAlign: "center"}}>
                <Img src={RxoneImage} /> {" "}
                {appointmentItemss?.prescription_status}

              </TableCell>
              <TableCell style={{textAlign: "center"}}>
                {tabRoute === "prescription" ? (
                  <Rating
                    name="read-only"
                    size="small"
                    className="mt-1"
                    value={appointmentItemss?.star_rating || 0}
                    readOnly
                  />
                ) : (
                  <span className="font-13 px-2">
                    {appointmentItemss?.appointment_time_formatted ||
                      appointmentItemss?.appointment_start_time ||
                      appointmentItemss?.prescription_date ||
                      ""}
                  </span>
                )}
              </TableCell>
              <TableCell className="appointment__container__lastsection__button" style={{textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem"}}>
                {tabRoute === "prescription" ? (
                  <div
                    style={{ gap: "0.2rem" }}
                    className="d-flex align-items-center"
                  >
                    {appointmentItemss?.review_comments ? (
                      <TooltipComponent
                        icon={
                          <NotesIcon
                            sx={{
                              width: "100%",
                              color: "#199a8e",
                              fontSize: "2rem",
                            }}
                          />
                        }
                        title={appointmentItemss?.review_comments}
                      />
                    ) : (
                      <NotesIcon
                        sx={{
                          width: "100%",
                          color: "#dddddd",
                          fontSize: "2rem",
                        }}
                        disabled
                      />
                    )}
                  </div>
                ) : appointmentItemss?.is_walkin_appointment &&
                  appointmentItemss?.is_inperson_appointment ? (
                  <StatusButton
                    type={
                      appointmentItemss?.appointment_token_no ||
                      appointmentItemss?.token_number
                    }
                    color="#EAA235"
                  />
                ) : (
                  <StatusButton type={appointmentItemss?.app_status} />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
